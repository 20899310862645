import React from "react"
import "./FestCircle.scss"

const FestCosmos = () => {
  return (
    <aside className="cosmos-place-hero">
      <div className="cosmos__box">
        Kosmo polis
        <div id="circle1" className="cosmos-circle__box">
          <div className="cosmos-circle">
            <div className="circle-planet"></div>
          </div>
        </div>
        <div id="circle2" className="cosmos-circle__box">
          <div className="cosmos-circle">
            <div className="circle-planet"></div>
          </div>
        </div>
        <div id="circleS" className="cosmos-circle__box">
          <div className="cosmos-circle">
            <div className="circle-planet"></div>
            <div className="circle-planet"></div>
            <div className="circle-planet"></div>
          </div>
        </div>
        <div id="circle3" className="cosmos-circle__box">
          <div className="cosmos-circle">
            <div className="circle-planet"></div>
          </div>
        </div>
        <div id="circle4" className="cosmos-circle__box">
          <div className="cosmos-circle">
            <div className="circle-planet"></div>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default FestCosmos
